import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div>
     <h1>Rachel Page</h1>
     <img src={logo} className="App-logo" alt="logo" />
    </div>
  );
}

export default App;
